(function(w, d) {
    // Check if service workers and the push api is supported
    if ('serviceWorker' in navigator === false || 'PushManager' in window === false) {
        throw new Error('The Push API and/or Service workers are disabled or not supported');
        return false;
    }

    window.__tnt || (window.__tnt = {});
    __tnt.notification || (__tnt.notification = {});
    __tnt.notification.subscriptions || (__tnt.notification.subscriptions = {});
    var localStorageSupported = __tnt.client.capabilities.supportsLocalStorage();

    var webPromo = {
        init: function() {
            w.web_promo_config.forEach(function(o) {
                var blockEl = d.getElementById ('web-promo-designer-' + o.block_id),
                    topicsEl = blockEl.querySelector('.topics-container'),
                    alertSuccessEl = blockEl.querySelector('.web-promo-designer-alert.alert-success'),
                    alertWarningEl = blockEl.querySelector('.web-promo-designer-alert.alert-warning'),
                    alertErrorEl = blockEl.querySelector('.web-promo-designer-alert.alert-danger'),
                    subscribeBtn = blockEl.querySelector('.sign-up'),
                    modal = blockEl.querySelector('.web-promo-designer-modal'),
                    subscribeNum = 0,
                    subscribeSuccesses = 0;

                // Show block since service workers and push api is supported
                blockEl.style.display = 'block';
                blockEl.offsetHeight; // Trigger a repaint so we can animate it

                if (localStorageSupported) {
                    // Check for old values and transfer to new value
                    if (localStorage.getItem('lastclear')) {
                        localStorage.setItem('tnt_notification_popup_' + o.block_id + '_shown', localStorage.getItem('lastclear'));
                        localStorage.removeItem('lastclear');
                    }
                    localStorage.removeItem('webPopState-' + o.block_id);

                    if (localStorage.getItem('tnt_notification_subscriptions')) {
                        __tnt.notification.subscriptions = JSON.parse(localStorage.getItem('tnt_notification_subscriptions'));
                    }
                }

                // If modal, determine if it should display
                if (o.overlay_action && modal) {
                    // Logic for checking if the user is already subscribed to everything
                    var topicInputs = topicsEl.querySelectorAll('input');
                    var subscribedToAll = true;
                    for (var i = 0; i < topicInputs.length; i++) {
                        // If input is not in user's subscriptions
                        if (__tnt.notification.subscriptions[topicInputs[i].value] === undefined || __tnt.notification.subscriptions[topicInputs[i].value] === 0) {
                            subscribedToAll = false;
                            break;
                        }
                    }
                    if (!subscribedToAll && localStorageSupported) {
                        var shown_timestamp = localStorage.getItem('tnt_notification_popup_' + o.block_id + '_shown');
                        if (shown_timestamp == null
                                || (o.display_again != '' && (parseInt(shown_timestamp) + (60 * 60 * 1000 * o.display_again) <= Date.now()))
                           ) {
                            if (o.display_timer != '') {
                                setTimeout(function() {
                                    modal.classList.add('in');
                                }, o.display_timer * 1000);
                            } else {
                                modal.classList.add('in');
                            }
                            localStorage.setItem('tnt_notification_popup_' + o.block_id + '_shown', Date.now());
                        }
                    }
                }

                function subscribe() {
                    // Hide all alerts
                    alertSuccessEl.classList.add('hide');
                    alertWarningEl.classList.add('hide');
                    alertErrorEl.classList.add('hide');

                    if (Notification.permission == 'granted') {
                        subscribeToChecked();

                    } else if (Notification.permission == 'default') {
                        alertWarningEl.innerText = 'Please allow notifications for this site in your browser settings.';
                        alertWarningEl.classList.remove('hide');

                        Notification.requestPermission().then(function(permission) {
                            if (permission === 'granted') {
                                subscribeToChecked();
                            } else if (permission === 'denied') {
                                // Close window;
                                alertWarningEl.classList.add('hide');
                                alertErrorEl.innerText = 'Notifications are blocked. Please allow notifications for this site in your browser settings and try again.';
                                alertErrorEl.classList.remove('hide');
                                if (modal) {
                                    modal.classList.remove('in');
                                }
                            }
                        });

                    } else if (Notification.permission == 'denied') {
                        alertErrorEl.innerText = 'Notifications are blocked. Please allow notifications for this site in your browser settings and try again.';
                        alertErrorEl.classList.remove('hide');
                    }
                }

                function subscribeToChecked() {
                    var topicsToSubscribe = [];
                    var topicInputs = topicsEl.querySelectorAll('input:checked');

                    // If not already subscribed to topic, push it to an array
                    for (var i = 0; i < topicInputs.length; i++) {
                        if (__tnt.notification.subscriptions[topicInputs[i].value] != 1) {
                            topicsToSubscribe.push(topicInputs[i].value);
                        }
                    }

                    if (topicsToSubscribe.length > 0) {
                        subscribeNum = topicsToSubscribe.length;
                        subscribeSuccesses = 0;

                        disableInputs();

                        for (let i = 0; i < topicsToSubscribe.length; i++) {
                            subscribeToTopic(topicsToSubscribe[i]);
                        }

                    } else {
                        // either they are subscribed to all topics already or they didn't select any to subscribe to
                        alertWarningEl.innerText = 'You\'re already subscribed to these topics.';
                        alertWarningEl.classList.remove('hide');
                    }
                }

                function subscribeToTopic(topic) {
                    TNCMS.Messaging.subscribe(topic).then(function() {
                        __tnt.notification.subscriptions[topic] = 1;
                        if (localStorageSupported) {
                            localStorage.setItem('tnt_notification_subscriptions', JSON.stringify(__tnt.notification.subscriptions));
                        }

                        // send out event that notify panel can respond to
                        var subscribeEvent = new CustomEvent('tnt-notification-subscribe', {
                            bubbles: true,
                            detail: { topic: topic }
                        });
                        blockEl.dispatchEvent(subscribeEvent);
                        checkSubscriptions(true);

                    }, function(err) {
                        console.error(err);
                        if (err.code == 'messaging/token-unsubscribe-failed') {
                            // if specific firebase error, retry after a half-second
                            setTimeout(function() {
                                subscribeToTopic(topic);
                            }, 500);
                        } else {
                            checkSubscriptions(false);
                        }
                    });
                }

                function checkSubscriptions(success) {
                    if (success) {
                        subscribeSuccesses += 1;
                        if (subscribeSuccesses == subscribeNum) {
                            // successfully subscribed to selected topics
                            alertSuccessEl.classList.add('hide');
                            alertWarningEl.classList.add('hide');
                            alertErrorEl.classList.add('hide');

                            alertSuccessEl.innerText = 'Success! Thank you for signing up!';
                            alertSuccessEl.classList.remove('hide');

                            enableInputs();

                            // Show notification panel
                            if (__tnt.notificationPanel) {
                                __tnt.notificationPanel.open();
                            }

                            // Close popup after a delay
                            if (modal) {
                                setTimeout(function() {
                                    modal.classList.remove('in');
                                }, 3000);
                            }
                        }
                    } else {
                        // there was an error with one
                        alertSuccessEl.classList.add('hide');
                        alertWarningEl.classList.add('hide');
                        alertErrorEl.classList.add('hide');

                        alertErrorEl.innerText = 'There was an issue signing up to one or more topics. Please try again.';
                        alertErrorEl.classList.remove('hide');

                        enableInputs();
                    }
                }

                function disableInputs() {
                    var inputs = topicsEl.querySelectorAll('input');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = true;
                    }
                    subscribeBtn.disabled = true;
                    subscribeBtn.querySelector('.tnt-spinner').style.display = 'inline-block';
                }

                function enableInputs() {
                    var inputs = topicsEl.querySelectorAll('input');
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = false;
                    }
                    subscribeBtn.disabled = false;
                    subscribeBtn.querySelector('.tnt-spinner').style.display = 'none';
                }

                // Event listener for subscribe button (sign up)
                subscribeBtn.addEventListener('click', subscribe);

                // Automatically attempt to subscribe to all topics in block
                // Doesn't work in Firefox because it is considered an annoyance to users
                if (o.user_interaction === 'disabled') {
                    subscribe();
                }

                // Logic for disabling the subscribe button if no inputs checked
                topicsEl.addEventListener('change', function(e) {
                    if (e.target.tagName == 'INPUT') {
                        var inputs = this.querySelectorAll('input');
                        var inputsSelected = false;
                        for (var i = 0; i < inputs.length; i++) {
                            if (inputs[i].checked) {
                                inputsSelected = true;
                                break;
                            }
                        }
                        if (!inputsSelected) {
                            subscribeBtn.disabled = true;
                        } else {
                            subscribeBtn.disabled = false;
                        }
                    }
                });

                // Event listener for closing popup
                if (blockEl.querySelector('.web-promo-designer-buttons .close-button') && modal) {
                    blockEl.querySelector('.web-promo-designer-buttons .close-button').addEventListener('click', function() {
                        modal.classList.remove('in');
                    });
                }

            });
        }
    }
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            webPromo.init();
        })
    } else {
        webPromo.init();
    }
})(window, document);